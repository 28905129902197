import React from 'react'
import Layout from '../layouts/main'
import ProgressiveBgImage from '../components/ProgressiveBgImage'


class FormSuccessPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={'Kiitos'}>
        <ProgressiveBgImage
          hqImage="/images/bluemonday-bg.jpg"
          lqImage="/images/bluemonday-bg-lq.jpg"
        >
          <section className="section">
          <p>Tack för ditt meddelande, vi återkommer!</p>
          </section>
         
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default FormSuccessPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
    
//     articles: allMarkdownRemark(
//       filter: { frontmatter: { content_type: { eq: "article" } } }
//     ) {
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             excerpt
//             description
//             featured_image
//             content_type
//           }
//         }
//       }
//     }

//     casinos: allMarkdownRemark(
//       filter: { frontmatter: { content_type: { eq: "casino" } } }
//       sort: { 
//         fields: [frontmatter___rating]
//         order: [DESC]
//       }
//     ) {
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             description
//             featured_image
//             logo
//             rating
//             content_type
//             offer
//             affiliate_link
//             bullet_points
//           }
//         }
//       }
//     }

//     games: allMarkdownRemark(
//       filter: { frontmatter: { content_type: { eq: "game" } } }
//       sort: { 
//         fields: [frontmatter___rating]
//         order: [DESC]
//       }
//     ) {
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             logo
//             rating
//             content_type
//             software_provider
//           }
//         }
//       }
//     }
//   }
// `
